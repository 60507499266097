import React from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import Flex, { FlexItem } from 'styled-flex-component';

import { useTrackedState } from '../lib/state';

import SubLayout from '../components/SubLayout';
import CovidArticleArea from '../components/CovidArticleArea';
import CovidArticleArea02 from '../components/CovidArticleArea02';
import { P } from '../components/common/P.style';
import { Img } from '../components/common/Img.style';
import Link from '../components/Link';
import { H2, H2Background, H3, H4 } from '../components/common/H.style';
import { UL } from '../components/common/UL.style'; //平畑追加
import { LI } from '../components/common/LI.style'; //平畑追加
import DepartmentSchedule from '../components/DepartmentSchedule';
import DoctorInCharge from '../components/DoctorInCharge';
import SymptomExamples from '../components/SymptomExamples';
import covidHero from '../assets/img/covid-hero.jpg';
import bana from '../assets/img/telemed-link.jpg';
import bana2 from '../assets/img/5ALA.jpg';


const CovidButton = styled(Flex)`
height: 40px;
width: 50%;
margin: 1em auto;
border-radius: 6px;
border: solid 4px #5A91DB;
background-color: #5A91DB;
box-sizing: border-box;
`;


const WhiteText = styled.div`
font-size: 18px;
color: #ffffff;
`;

const Covid19ArticleList = ({ list = [] }) => (
  <Flex column>
    {list.map(({ id, date, title, path }, index) => (
      <Flex
        key={id}
        css={`
          height: 60px;
          margin: 5px 0;
          border-bottom: ${(list.length - 1 !== index && 'solid 1px #ccc') || 'inherit'};
        `}
      >
        <Flex center>
          <span
            css={`
              font-size: 12px;
              color: #666666;
              margin-right: 30px;
            `}
          >
            {date}
          </span>
        </Flex>
        <Flex center>
          <Link to={path}>
            <span
              css={`
                font-size: 16px;
                color: #3b4043;
                overflow-wrap: anywhere;
              `}
            >
              {title}
            </span>
          </Link>
        </Flex>
      </Flex>
    ))}
  </Flex>
);

const Covid19 = ({ data: { allMarkdownRemark, allFile } }) => {
  const { size } = useTrackedState();
  const covid19Articles = allMarkdownRemark.edges
    .filter(
      edge => !!edge.node.frontmatter.date
    )
    .map(({ node: { id, frontmatter: { title, path } } }) => ({
      id,
      title,
      path,
    }));
  return (
    <SubLayout topTitle="新型コロナ後遺症外来">
      <Img src={covidHero} />
      <Flex
        justifyAround
        wrap="true"
        css={`
        margin: 20px 0;
      `}
      >
      </Flex>
      <H3>新型コロナ後遺症かも？とお悩みの方</H3>
      <P>
      <b>・外来診療</b><br/>
        ご予約は下記代表電話にて承っております。<br />
        予約代表：０３－３４００－３２８８<br />
        ※お問い合わせが多いため、つながりにくいこともございますが予めご了承ください。<br />
        ※現在、予約が大変混み合っておりますので約2ヶ月先のご予約となります。<br />
        ※予約せずに<b><a href="./schedule">受付時間内</a></b>に来院し、診察をお待ちいただいても結構でございます。<br />
        その際、6時間以上お待ちいただくことがございますので、余裕をもってお越しいただくことをおすすめいたします。 <br />
        ※当日の状況により予約外の受付人数制限をする場合がございます。 <br />
        なお、外出される方は、外出される時、戻られた時に、必ず受付にお声かけください。<br />
      </P>

      <P>
      <b>・オンライン診療</b><br/>
        オンライン診療は事前予約はできません。当院のオンライン診療の詳細はこちらのサイトをご覧ください<br /><br />
      </P>
      <P css={`text-align: center;`}>
        <Link to="https://telemed-app.hirahata-clinic.or.jp/?locate=shibuya">
          <Img src={bana}
            css={`width: 50%;`} />
        </Link>
      </P>
      <P>
        <b css={"font-size: 1.25em;"}>新型コロナ後遺症についての詳しい情報は<a href="https://www.longcovid.jp/" target="_blank" css={"color:blue;"}>こちら https://www.longcovid.jp/</a></b><a href="https://www.longcovid.jp/" target="_blank">（別ページに移動します）</a><br /><br />
      </P>

      <H3>当院の感染対策</H3>
      <UL>
        <LI>窓を開けての開院時間中の<b>常時換気</b></LI>
        <LI>健診・検査で受診者が触れる場所を毎回消毒</LI>
        <LI>スタッフの手指洗浄励行</LI>
        <LI>感冒症状の方の隔離</LI>
      </UL>
      
      <H3>新型コロナの後遺症疑い症例の治療</H3>
      <Flex
        wrap="true"
        justifyAround
        css={`
        margin-bottom: 30px;
      `}
      >
        <FlexItem
          css={`
          max-width: 600px;
        `}
        >
        <P>
          2020年２月以降、微熱・倦怠感・強い疲労感・しびれ・呼吸苦・頭痛・食欲不振などの症状が長引いたり、出たり消えたりしている方々が世界中で報告されています。<br />
          当院では、このような方々の治療を積極的に行っております。<br />
          「心因性」「自律神経失調症」と診断して単なる我慢を促すことはありません。<br />
          漢方を中心に、西洋医学の薬も適宜使いながら治療していきます。<br />
          現在は、北海道から沖縄まで、オンライン診療で多くの患者様を診させていただいております。<br />
          <br />
          
          初診でもオンライン診療が可能です。詳しくは <a href="https://telemed-app.hirahata-clinic.or.jp/" target="_blank"css={"color:blue;"}>こちら</a>から（https://telemed-app.hirahata-clinic.or.jp/）<br />

          ただいま多くの方にご相談いただいているため、院長のオンライン診療の待ち時間が大変長くなっております（8〜12時間待ち）。<br />
          前回と同じ薬の継続をご希望の場合は、他の医師でもいい旨をご記載ください。<br /><br />
                    
        </P>
        </FlexItem>
        <SymptomExamples
          list={[
            '長引く微熱（断続的な場合含む）',
            '倦怠感、疲労感',
            '食欲不振',
            '咳、喘息様の症状',
            '長引く下痢',
            '腹痛',
            '頭のほてり、血が上る感じ',
            '頭重感、頭が詰まる感じ',
            '胸の違和感・痛み',
            '関節痛',
            '悪寒',
            '手足の血管が浮き出る',
            '皮疹',
          ]}
        />
      </Flex>

      <P>
      <iframe width="560" height="315" 
        src="https://www.youtube.com/embed/8hX9CgraPuU" 
        frameborder="0" allow="autoplay; encrypted-media" allowfullscreen>
      </iframe>
      </P>
      <P>
      <iframe width="560" height="315" 
        src="https://www.youtube.com/embed/SnV69lbh19A" 
        frameborder="0" allow="autoplay; encrypted-media" allowfullscreen>
      </iframe>
      </P>
      <P>
      <iframe width="560" height="315" 
        src="https://www.youtube.com/embed/fySiMZvBF4U" 
        frameborder="0" allow="autoplay; encrypted-media" allowfullscreen>
      </iframe>
      </P>
      <H4>筋痛性脳脊髄炎&#x2F;慢性疲労症候群（ME&#x2F;CFS）への移行</H4>
      <P>
        筋痛性脳脊髄炎&#x2F;慢性疲労症候群（ME&#x2F;CFS）は、少しの労作の後、極端な疲労感そのほかの症状をきたす疾患です。<br />
        SARSのあと、ME&#x2F;CFSが多発したことが報告されていますが、新型コロナの感染後にも同様の症状をきたすことが少なくありません。<br />
        <strong>一番大切な大原則は、「だるくなることをしない」</strong>です。<br />
        「体力（筋力）のために」と思って運動すると、その後急激に状態が悪くなる方がたくさんおられますので、疲労感を感じておられる方は、できるだけ家の中でだるくならない範囲でのみ動くように注意してください。<br />
        だるくならないようにしていただくだけで、症状の悪化を防ぐことができます。
      </P>

      <H4>各種書類について</H4>
      <P>
        当院では書類の発行のみは対応いたしかねます。<br/>
        必ず診察をご受診ください。<br/>
        取り扱い書類<br/>
        　・普通診断書（当院書式）<br/>
        　・診断書（規定様式）<br/>
        　・各種証明書<br/>
        　・傷病手当金申請書<br/>
        　・労災指定用紙<br/>
        ※以下の二種類は対面診療でのみ発行可能です。<br/>
          ・同意書（はり・きゅう）<br/>
          ・同意書（マッサージ）<br/>
        　※その他書類に関してはメールにてお問合せください。<br/>
        各種書類の原本をお持ちの方<br/>
        　●外来診療→診察時必ずご持参ください。<br/>
        　●オンライン診療→事前にご郵送いただき、オンライン診療のお申し込みをお願いいたします。<br/>
        　※郵便物の到着に1週間ほどかかる場合がございます。<br/>
        　投函後ゆとりをもってお申し込みをお願い申し上げます。<br/>
      </P>

      <H3>抗体検査</H3>
      <P>
        当院では、抗体検査を施行しています。<br />
        （申し訳ありませんが、保険・公費での抗体検査は施行しておりません。）<br />
      </P>
      <P>
        <Link to="/covid-pcr">
          <CovidButton center >
            <WhiteText>新型コロナ検査(PCR・抗体)ご予約案内ページ</WhiteText>
          </CovidButton>
        </Link>
      </P>

      <H3>新型コロナ感染症・後遺症の記事</H3>
        <CovidArticleArea data={covid19Articles} size={size} hasLimit={true} />
      
      <H3>メール受信許可設定のお願い</H3>
      <P>
        当院のオンライン診療等の申し込みやお問い合わせをされた方には、『@hirahata-clinic.or.jp』のメールドメインより「受付完了」や「お問い合わせ内容の返信」等のメールを送らせていただいております。<br />
        しかし、メールが送信されているにもかかわらず届かないというケースが多発しています。<br />
        当院からお送りしたメールの受信が確認出来ない際は、以下の内容をお確かめください。
        <UL>
          <LI>
            <b>ご登録いただいたメールアドレスが間違っている</b><br />
            メールアドレスが正しく入力されていないと、配信されたメールがお手元に届きません。<br />
            メールアドレスのつづりなどお間違いがないかご確認ください。
          </LI>
          <LI>
            <b>メーラー（メールソフト）の設定が間違っている</b><br />
            ご利用されているメールソフト、メールサービス、セキュリティソフトにおけるお客様の設定によっては、自動的に既読、ごみ箱に移行される場合があります。<br />
            ご利用メールソフト、メールサービス、セキュリティソフトの設定をご確認ください。
          </LI>
          <LI>
            <b>キャリアメールアドレスの場合、ドメイン設定を行っている</b><br />
            迷惑メールの対策などでドメイン指定を行っている場合、メールが受信できない場合があります。<br />
            『@hirahata-clinic.or.jp』を受信設定してください。
            ドメイン指定の解除方法について詳しくは、下記の「ドメイン指定受信について」をご確認ください。
          </LI>
        </UL>
        <H4>ドメイン指定受信の設定方法</H4>
        許可設定をしていただくドメインは『@hirahata-clinic.or.jp』です。<br />
        設定方法は各サイトにてご確認ください。<br />
        <UL>
          <LI>
            <b>auユーザーの方</b><br />
            <Link to="https://www.au.com/support/service/mobile/trouble/mail/email/filter/detail/domain/" extarnal >受信リスト設定（auサイト）</Link>
          </LI>
          <LI>
            <b>docomoユーザーの方</b><br />
            <Link to="https://www.nttdocomo.co.jp/info/spam_mail/domain/" extarnal >受信リスト／拒否リスト設定（docomoサイト）</Link>
          </LI>
          <LI>
            <b>Softbankユーザーの方</b><br />
            <Link to="https://www.softbank.jp/mobile/support/mail/antispam/email-i/white/" extarnal >受信許可リスト設定（Softbankサイト）</Link>
          </LI>
          <LI>
            <b>Yahoo!メールユーザーの方</b><br />
            <Link to="https://support.yahoo-net.jp/PccMail/s/article/H000007299" extarnal >届くはずのメールが届いてない（Yahoo!メールサイト）</Link>
          </LI>
          <LI>
            <b>Gmailユーザーの方</b><br />
            <Link to="https://support.google.com/mail/answer/6579?hl=ja" extarnal >メールのフィルタルールの作成（Googleサイト）</Link>
          </LI>
          <LI>
            <b>Hotmail / Outlookユーザーの方</b><br />
            <Link to="https://support.microsoft.com/ja-jp/office/%e8%bf%b7%e6%83%91%e3%83%a1%e3%83%bc%e3%83%ab-%e3%83%95%e3%82%a3%e3%83%ab%e3%82%bf%e3%83%bc%e3%82%92%e4%bd%bf%e7%94%a8%e3%81%97%e3%81%a6%e3%80%81%e8%a1%a8%e7%a4%ba%e3%81%99%e3%82%8b%e3%83%a1%e3%83%83%e3%82%bb%e3%83%bc%e3%82%b8%e3%82%92%e7%ae%a1%e7%90%86%e3%81%99%e3%82%8b-274ae301-5db2-4aad-be21-25413cede077?ui=ja-JP&rs=ja-JP&ad=JP#__toc304379666" extarnal >信頼できる送信者がブロックされないようにする（Microsoftサイト）</Link>
          </LI>
          <LI>
            <b>So-netユーザーの方</b><br />
            <Link to="https://www.so-net.ne.jp/option/security/meiwaku/howto/setup/passlist.html" extarnal >パスリスト一覧への条件追加（So-netサイト）</Link>
          </LI>
          <LI>
            <b>Exciteユーザーの方</b><br />
            <Link to="https://bbhelp.excite.co.jp/hc/ja/articles/115000135661-%E5%88%A9%E7%94%A8-%E8%A8%AD%E5%AE%9A-BB-excite%E3%83%A1%E3%83%BC%E3%83%AB%E5%88%A9%E7%94%A8%E8%80%85%E6%A9%9F%E8%83%BD%E3%81%AE%E8%A8%AD%E5%AE%9A%E6%96%B9%E6%B3%95#%E3%83%9B%E3%83%AF%E3%82%A4%E3%83%88%E3%83%AA%E3%82%B9%E3%83%88%E8%A8%AD%E5%AE%9A" extarnal >ホワイトリスト設定（Exciteサイト）</Link>
          </LI>
          <LI>
            <b>OCNユーザーの方</b><br />
            <Link to="https://support.ntt.com/ocn/support/pid2900000q7f" extarnal >スマートフォンでの設定（NTTサイト）</Link><br />
            <Link to="https://support.ntt.com/ocn/support/pid2900000q66" extarnal >PCでの設定（NTTサイト）</Link>
          </LI>
          <LI>
            <b>iCloudユーザーの方</b><br />
            <Link to="https://support.apple.com/ja-jp/guide/icloud/mm6b1a3b04/icloud" extarnal >メールの受信に関する問題がある場合（Appleサイト）</Link><br />
            <Link to="https://support.apple.com/ja-jp/guide/icloud/mm6b1a490a/1.0/icloud/1.0" extarnal >iCloud.comの「メール」で迷惑メールを管理する（Appleサイト）</Link>
          </LI>
        </UL>
      </P>
    </SubLayout>
  );
};

export default Covid19;

export const pageQuery = graphql`
  query {
    allMarkdownRemark(
      limit:4,
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { fileAbsolutePath: { regex: "/covid19-articles/" } }
    ) {
      edges {
        node {
          id
          excerpt(pruneLength: 500)
          frontmatter {
            date(formatString: "YYYY/MM/DD")
            title
            path
          }
        }
      }
    }
}`;


