import React from 'react';
import styled from 'styled-components';
import Flex, { FlexItem } from 'styled-flex-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';

import Link from './Link';

const Text = styled.span`
  font-size: 18px;
  color: #666666;
`;

const CovidArticleArea = ({ data, size, hasLimit }) => {
  const dateRightMargin = (size === 'sm' && '10px') || '30px';
  return (
    <Flex column>
      {data.map(({ id, date, title, path }, index) => (
        <FlexItem key={id}>
          <Link to={path}>
            <Flex
              css={`
                min-height: 50px;
                border-bottom: ${data.length - 1 !== index && 'solid 1px #6f6f6f'};
              `}
              wrap={(size === 'sm' && 'true') || 'false'}
            >
              <Flex
                column
                justifyCenter
                css={`
                  margin-right: ${dateRightMargin};
                  margin-bottom: 5px;
                `}
              >
                <Text>{date}</Text>
              </Flex>
              <Flex column justifyCenter>
                <Text
                  css={`
                    overflow-wrap: anywhere;
                    margin-bottom: 5px;
                  `}
                >
                  {title}
                </Text>
              </Flex>
            </Flex>
          </Link>
          {/* TODO <div>{excerpt}</div> */}
        </FlexItem>
      ))}
      {hasLimit && (
        <FlexItem key="go-to-annoucements-list-page">
          <Link to="/covid-article-list01">
            <Flex
              justifyEnd
              css={`
                height: 50px;
                border-top: solid 1px #6f6f6f;
                border-bottom: solid 1px #6f6f6f;
              `}
            >
              <Flex column justifyCenter>
                <Text
                  css={`
                    font-size: 1em;
                    font-weight: 600;
              `}
            >
             新型コロナ感染症・後遺症の記事一覧を見る</Text>
              </Flex>
              <Flex column justifyCenter>
                <FontAwesomeIcon
                  icon={faChevronRight}
                  style={{ fontSize: 12, marginLeft: 10 }}
                  color="#000"
                />
              </Flex>
            </Flex>
          </Link>
        </FlexItem>
      )}
    </Flex>
  );
};

export default CovidArticleArea;
